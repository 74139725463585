import React, { Component } from 'react';
import { createBrowserHistory } from 'history';

import {
    Row, Col,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input,
    UncontrolledTooltip
    // Container,
    // Form, FormGroup, Label, Input
} from 'reactstrap';
import moment from 'moment';
import Button from 'reactstrap/lib/Button';
import { humanize } from '../../box-helpers';
import Alert from 'reactstrap/lib/Alert';

import StarRating from "../Layout/StarRating";

class BoxJob extends Component {
    state = {
        box_job: {},
        openFlagModal: false,
        openRateModal: false,
        openReviewsModal: false,
        alertMsg: "",
        showAlert: false,
        rating: 0,
        comment: "",
    }

    constructor(props) {
        super(props)

        this.toggleFlagModal = this.toggleFlagModal.bind(this);
        this.deleteFlaggedItem = this.deleteFlaggedItem.bind(this);
        this.printFlaggedItem = this.printFlaggedItem.bind(this);
        if (this.props.box_job) {
            this.state.box_job = this.props.box_job;
        }
    }

    mapDesignNames =() => {
        if (this.state.box_job.box_job_queue_items) {

            let design_names = this.state.box_job.box_job_queue_items.map((qi) => {
                let design_name = "";
                if (qi.ignore_flags === true) {
                    design_name = "PRINT AS IS \n";
                }
                if (qi.box_design_name && qi.box_design_name !== "") {
                    design_name += "Drill: " + qi.box_design_name + " \n";
                } else if (qi.box_uv_design_name && qi.box_uv_design_name !== "") {
                    design_name += "Uv: " + qi.box_uv_design_name + " \n";
                }
                if (qi.box_job_queue_item_services) {
                    let queue_service = qi.box_job_queue_item_services.map((service) => {
                        return service.qty + " " + service.name;
                    })
                    design_name = design_name + " " + queue_service;
                }
                return design_name;
            })
            return design_names;
        } else {
            return "";
        }
    }

    mapFlagNames =() => {
        if (this.state.box_job.box_job_queue_items && this.state.box_job.has_flag) {

            let design_names = this.state.box_job.box_job_queue_items.map((qi) => {
                let flag_names = "";

                if (qi.box_job_flags) {
                    let flags = qi.box_job_flags.map((flag) => {
                        return flag.customer_label + "\n";
                    })
                    flag_names = flags;
                }
                return flag_names;
            })
            return design_names;
        } else {
            return "";
        }
    }

    mapNoteImages =() => {
        if (this.state.box_job.box_job_queue_items && this.state.box_job.has_flag) {
            let note_images = this.state.box_job.box_job_queue_items.map((qi) => {
                if (qi.note_image_url) {
                    return <a href={qi.note_image_url} target='_blank' rel="noopener noreferrer" >
                        <img src={qi.note_image_thumb_url} alt="Note" className="img-fluid" />
                    </a>
                } else {
                    return "";
                }
            })
            return note_images;
        }
    }

    toggleFlagModal = () => {
        this.setState({openFlagModal: !this.state.openFlagModal})
    }

    toggleRateModal = () => {
        this.setState({openRateModal: !this.state.openRateModal})
    }

    onRatingChange = (new_rating) => {
        this.setState({
            rating: new_rating
        })
    }

    onCommentChange = (e) => {
        this.setState({
            comment: e.target.value
        })
    }

    toggleReviewsModal = () => {
        this.setState({openReviewsModal: !this.state.openReviewsModal})
    }

    deleteFlaggedItem=() => {
        this.setState({
            showAlert: false
        }, async () => {
            let token = JSON.parse(localStorage.getItem("token"));
            var result =  await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/'+this.state.box_job.id, {
                method: 'DELETE',
                headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                this.setState({ showAlert: true })
                return error.message;
            });


            this.processResult(result, this.state.showAlert);
        })
    }

    printFlaggedItem=() => {
        this.setState({
            showAlert: false
        }, async () => {
            let token = JSON.parse(localStorage.getItem("token"));
            var result =  await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/'+this.state.box_job.id, {
                method: 'PUT',
                headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ignore_flags:  true })
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                this.setState({ showAlert: true })
                return error.message;
            });

            this.processResult(result, this.state.showAlert, true);
        })
    }

    submitReview = async () => {
        if(this.state.rating === 0){
            this.setState({ alertMsg: "Please select a rating between 1 to 5 stars", showAlert: true})
        // }else if(this.state.comment.trim() == ""){
        //     this.setState({ alertMsg: "Please write a review in the Comment section", showAlert: true})
        }else{
            this.setState({ alertMsg: "", showAlert: false})

            let token = JSON.parse(localStorage.getItem("token"));
            var result =  await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/' + this.state.box_job.id + '/box_job_reviews', {
                method: 'POST',
                headers: {
                    'Authorization': "Bearer " + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ rating: this.state.rating, comment: this.state.comment })
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch((error) => {
                this.setState({ showAlert: true})
                return error.message;
            });

            if(result["errors"]){
                console.log('result error: ',result["errors"]);
                let errors = "";
                if (typeof result["errors"] === 'string') {
                    errors = result["errors"];
                } else {
                    errors = Object.keys(result["errors"]).map(key => {
                        let human_key = humanize(key);
                        return  human_key + " " + result["errors"][key];
                    })
                }
                this.setState({ alertMsg: errors, showAlert: true})
            }else{
                let box_job = this.state.box_job;

                box_job.box_job_review = result.box_job_review;
                box_job.ready_for_review = false;
                this.setState({ box_job, openRateModal: false });
                // console.log(this.state.box_job);


            }
        }
    }

    processResult = (result, is_error, reload_window) => {

        if (is_error) {
            this.setState({ alertMsg: "", showAlert: true})
        } else if(result["errors"]){
            console.log('result error: ',result["errors"]);
            let errors = "";
            if (typeof result["errors"] === 'string') {
                errors = result["errors"];
            } else {
                errors = Object.keys(result["errors"]).map(key => {
                    let human_key = humanize(key);
                    return  human_key + " " + result["errors"][key];
                })
            }
            this.setState({ alertMsg: errors, showAlert: true})
        }else if (reload_window) {
            window.location.reload();
        }else{
            const history = createBrowserHistory();
            history.push({ pathname: '/box-jobs/'+this.state.box_job.order_number + "?ebjid=" + this.state.box_job.id })
            window.location.reload(false);
        }

    }

    editBoxJob = () => {
        const history = createBrowserHistory();
        history.push({ pathname: '/box-jobs/'+this.state.box_job.order_number + "?ebjid=" + this.state.box_job.id })
        window.location.reload(false);
    }

    render() {
        return  (
            <div>
                { this.state.box_job && this.state.box_job.quantity > 0 && (
                <Row className={ this.state.box_job.has_flag ? "bg-warning" : "border-bottom" }>
                    <Col className="col" md={2} >
                        <div>{ this.state.box_job.order_number }</div>
                    </Col>
                    <Col className="col" md={1} >
                        <div>{this.state.box_job.quantity }</div>
                    </Col>
                    <Col className="col" md={4} >
                        <div>{this.state.box_job.enclosure_type} {this.state.box_job.powder_color}</div>
                        { this.state.box_job.box_job_queue_items && (
                            <div className="alert alert-secondary p-1 m-0" style={{whiteSpace: 'pre', overflow: 'auto'}}>{ this.mapDesignNames() }</div>
                        )}
                    </Col>
                    <Col className="col" md={3}  style={{whiteSpace: 'pre'}}>
                        <div>{this.state.box_job.status }</div>
                        { this.state.box_job.has_flag && <div class="text-danger font-weight-bold">PROBLEM</div> }
                        {this.state.box_job.ready_for_review && !this.state.box_job.box_job_review &&
                            <span>
                                <Button color="primary" size="sm" block className="my-1" onClick={this.toggleRateModal}>Submit Review</Button>
                                <Modal isOpen={this.state.openRateModal} className="">
                                    <ModalHeader>Rate Order #{this.state.box_job.order_number}</ModalHeader>
                                    <ModalBody>
                                        <Form>
                                            <FormGroup row>
                                                <Label for="exampleText" sm={2}>Rate:</Label>
                                                <Col sm={10}>
                                                    <StarRating onRatingChange={this.onRatingChange} size={"lg"}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="exampleText" sm={2}>Comment:</Label>
                                                <Col sm={10}>
                                                    <Input type="textarea" name="text" id="exampleText" placeholder="Give us some details (optional)" value={this.state.comment} onChange={(e) => this.onCommentChange(e)}/>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                        <Alert color="danger" isOpen={this.state.showAlert === true}>
                                            { this.state.alertMsg}
                                        </Alert>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={this.submitReview}>Submit Review</Button>{' '}
                                        <Button color="secondary" onClick={this.toggleRateModal}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </span>
                        }
                        {this.state.box_job.box_job_review &&
                            <span>
                                <Button color="primary" size="sm" block className="my-1" onClick={this.toggleReviewsModal}>View Review</Button>
                                <Modal isOpen={this.state.openReviewsModal} className="">
                                    <ModalHeader>Order #{this.state.box_job.order_number} Reviews <small>{moment(this.state.box_job.box_job_review.created_at).format("MMM DD YYYY h:mm A")}</small></ModalHeader>
                                    <ModalBody>
                                        <Form>
                                            {console.log(this.state.box_job.box_job_review)}
                                            <FormGroup row>
                                                <Label for="exampleText" sm={2}>Rate:</Label>
                                                <Col sm={10}>
                                                    <StarRating rating={this.state.box_job.box_job_review.rating} size={"lg"}/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label for="exampleText" sm={2}>Comment:</Label>
                                                <Col sm={10}>
                                                    <Input type="textarea" name="text" id="exampleText" value={this.state.box_job.box_job_review.comment} disabled/>
                                                </Col>
                                            </FormGroup>
                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.toggleReviewsModal}>Cancel</Button>
                                    </ModalFooter>
                                </Modal>
                            </span>
                        }
                    </Col>
                    <Col className="col text-right" md={2}>
                        <div className="text-small">{ moment(this.state.box_job.created_at).format("MMM DD YYYY h:mm A") }</div>
                        { this.state.box_job.can_add_uv_product &&
                            <div>
                                <Button id="addUVJobTooltip" key="addUVJobTooltip" className="mt-2" color="info" onClick={this.editBoxJob}>Add UV Job</Button>
                                <UncontrolledTooltip placement="right" target={"addUVJobTooltip"}>
                                    Please use this button if you want to attach UV printing to this job.
                                </UncontrolledTooltip>
                            </div>
                        }
                    </Col>
                </Row>
                )}
                { this.state.box_job.has_flag && (
                    <Row className="bg-warning border-bottom pt-1">
                        <Col md={2} className="pt-1 pr-0">
                            <div className="text-medium text-right">Issue Flagged:</div>
                        </Col>
                        <Col md={7}>
                            <div>
                                <div className="alert alert-light p-1 m-0 text-danger" style={{maxHeight: '55px', overflow: 'auto'}}>
                                    { this.mapFlagNames() }
                                </div>
                                <div>
                                    { this.mapNoteImages() }
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <Button id="startOverTooltip" key="startOverTooltip" color="danger" size="sm" onClick={this.toggleFlagModal} >Start Over</Button>
                            <UncontrolledTooltip placement="right" target={"startOverTooltip"}>
                                Please use this button to recreate your job, this button will delete old jobs ( both drill and uv ) and you can attach new templates.
                            </UncontrolledTooltip>
                            <Button id="printAsIsTooltip" key="printAsIsTooltip" className="ml-2" color="info" size="sm" onClick={this.toggleFlagModal}>Print As Is</Button>
                            <UncontrolledTooltip placement="right" target={"printAsIsTooltip"}>
                                Please use this button if you will accept the results and ignore alerts.
                            </UncontrolledTooltip>
                            <Modal isOpen={this.state.openFlagModal} className="">
                                <ModalHeader>Resubmit UV Job</ModalHeader>
                                <ModalBody>
                                    <h4>There was a problem and the UV file for this job needs to be resubmited.</h4>
                                    <div className="alert alert-warning text-danger">
                                        { this.mapFlagNames() }
                                    </div>
                                    <div>
                                        { this.mapNoteImages() }
                                    </div>
                                    <div>Click Start Over to delete the UV file for this job. You will need to resubmit the file for this job. You do not need to resubmit the drill items.</div>
                                    <div>Click Print As Is to confirm that you don't want to correct the file, print it as it is.</div>
                                    <Alert color="danger" isOpen={this.state.showAlert === true}>
                                        { this.state.alertMsg}
                                    </Alert>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="danger" onClick={this.deleteFlaggedItem}>Start Over</Button>{' '}
                                    <Button color="info" onClick={this.printFlaggedItem}>Print As Is</Button>{' '}
                                    <Button color="secondary" onClick={this.toggleFlagModal}>Cancel</Button>
                                </ModalFooter>
                            </Modal>
                        </Col>
                    </Row>
                )}
            </div>
        )
    }
}
export default BoxJob;