import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
// import queryString from 'query-string';
import {
    // Row,
    Col,
    Container,
    Input,
    FormGroup,
    Label,
    // FormText,
    // Form,
    Alert,
    Button,
    Collapse
} from 'reactstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getBoxTypes, getBoxSides, getDefaultDrillHoleQuantity } from '../../box-helpers';

class ExisitingCustomerBoxJobForm extends Component {

    state ={
        order: [],
        drillSkus: [],
        uvSkusA: [],
        uvSkusB: [],
        uvSkusC: [],
        uvSkusD: [],
        uvSkusE: [],
        uvSkusLid: [],
        allDrillProducts: [],
        allUvProducts: [],
        glossProducts: [],
        colorProducts: [],
        whiteProducts: [],
        enclosures: [],
        availableQuantities: [],
        boxDesign: '',
        boxDesignlabel: "",
        boxDesigns: [],
        availableBoxDesigns: [],
        boxUvDesignA: '',
        boxUvDesignAlabel: "",
        boxUvDesignsA: [],
        boxUvDesignB: '',
        boxUvDesignBlabel: "",
        boxUvDesignsB: [],
        boxUvDesignC: '',
        boxUvDesignClabel: "",
        boxUvDesignsC: [],
        boxUvDesignD: '',
        boxUvDesignDlabel: "",
        boxUvDesignsD: [],
        boxUvDesignE: '',
        boxUvDesignElabel: "",
        boxUvDesignsE: [],
        boxUvDesignLid: '',
        boxUvDesignLidlabel: "",
        boxUvDesignsLid: [],
        orderNumber: null,
        boxType: '',
        boxTypes: getBoxTypes(),
        boxSides: getBoxSides(),
        drillHoleQuantity: getDefaultDrillHoleQuantity(),
        drillSku: '',
        drillSkulabel: 'Select Drill Product',
        uvSkuA: '',
        uvSkuAlabel: 'Select Side A Uv Product',
        uvSkuB: '',
        uvSkuBlabel: 'Select Side B Uv Product',
        uvSkuC: '',
        uvSkuClabel: 'Select Side C Uv Product',
        uvSkuD: '',
        uvSkuDlabel: 'Select Side D Uv Product',
        uvSkuE: '',
        uvSkuElabel: 'Select Side E Uv Product',
        uvSkuLid: '',
        uvSkuLidlabel: 'Select Lid Uv Product',
        enclosureSku: '',
        enclosureSkulabel: 'Select Enclosure Product',
       colorSkuA: '',
        colorSkuAlabel: 'Select Color Product',
        whiteSkuA: '',
        whiteSkuAlabel: 'Select White Product',
        glossSkuA: '',
        glossSkuAlabel: 'Select Gloss Product',
        colorSkuB: '',
        colorSkuBlabel: 'Select Color Product',
        whiteSkuB: '',
        whiteSkuBlabel: 'Select White Product',
        glossSkuB: '',
        glossSkuBlabel: 'Select Gloss Product',
        colorSkuC: '',
        colorSkuClabel: 'Select Color Product',
        whiteSkuC: '',
        whiteSkuClabel: 'Select White Product',
        glossSkuC: '',
        glossSkuClabel: 'Select Gloss Product',
        colorSkuD: '',
        colorSkuDlabel: 'Select Color Product',
        whiteSkuD: '',
        whiteSkuDlabel: 'Select White Product',
        glossSkuD: '',
        glossSkuDlabel: 'Select Gloss Product',
        colorSkuE: '',
        colorSkuElabel: 'Select Color Product',
        whiteSkuE: '',
        whiteSkuElabel: 'Select White Product',
        glossSkuE: '',
        glossSkuElabel: 'Select Gloss Product',
        colorSkuLid: '',
        colorSkuLidlabel: 'Select Color Product',
        whiteSkuLid: '',
        whiteSkuLidlabel: 'Select White Product',
        glossSkuLid: '',
        glossSkuLidlabel: 'Select Gloss Product',
        whiteSkuQty: 0,
        colorSkuQty: 0,
        glossSkuQty: 0,
        boxJob: '',
        boxJoblabel: '',
        existingBoxJobId: false,
        quantity: 'Select Quantity',
        drillCollapse: false,
        uvCollapseA: false,
        uvCollapseB: false,
        uvCollapseC: false,
        uvCollapseD: false,
        uvCollapseE: false,
        uvCollapseLid: false,
        uvCollapseADesign: false,
        uvCollapseBDesign: false,
        uvCollapseCDesign: false,
        uvCollapseDDesign: false,
        uvCollapseEDesign: false,
        uvCollapseLidDesign: false,
        collapseText: "Collapse",
        success: '',
        submitted: '',
        collapse: false,
        errors: {
            enclosureSku: '',
            drillSku: '',
            uvSkuA: '',
            uvSkuB: '',
            uvSkuC: '',
            uvSkuD: '',
            uvSkuE: '',
            uvSkuLid: '',
            boxDesign: '',
            boxUvDesignA: '',
            boxUvDesignB: '',
            boxUvDesignC: '',
            boxUvDesignD: '',
            boxUvDesignE: '',
            boxUvDesignLid: '',
            boxType: '',
            form: '',
            drillError: ''
        },
        loading: false
    }

    constructor(props) {
        super(props);
        // this.handleChange = this.handleChange.bind(this);
        // console.log('holes', this.props.holes)

        this.state.boxType = props.boxType;
        this.state.orderNumber = props.orderNumber;
        this.state.existingBoxJobId = props.existingBoxJobId;

    }

    componentDidMount = () => {

        if(this.state.orderNumber){
            this.setState({
                loading: true
            }, () => {
                this.getOrder(this.state.orderNumber, this.state.existingBoxJobId)
            })
        }
    }

    getOrder = async (orderNumber) => {
        let token = JSON.parse(localStorage.getItem("token"));
        let bjid = this.state.existingBoxJobId ? "?box_job_id="+this.state.existingBoxJobId : ""
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/' + orderNumber + bjid, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });
        // console.log(result.enclosure_products);
        if(result.order_number){
            let maxQuantity = 0;
            let enclosureSku, boxType, enclosureSkulabel;
            let drillSku, drillSkulabel;
            let uvSku, uvSkulabel;
            let availableUvProducts = [];
            // let availableDrillProducts = [];
            if (result && result.enclosure_products && result.enclosure_products.length > 0) {
                maxQuantity = result.quantity;
                let enclosure_products = result.enclosure_products.reduce((results, key) => {
                    // console.log('key1', key)
                    if (key.available_uv_quantity > 0 || key.available_drill_quantity > 0) {
                        if (maxQuantity === undefined){
                            maxQuantity = key.available_drill_quantity >= key.available_uv_quantity ? key.available_drill_quantity : key.available_uv_quantity
                        }
                        // maxQuantity = key.available_drill_quantity <= key.available_uv_quantity ? key.available_drill_quantity : key.available_uv_quantity
                        // maxQuantity.push({ box_type: key.attribute_value, available_uv_quantity: key.available_uv_quantity, available_drill_quantity: key.available_drill_quantity});
                        results.push({
                            value: key.sku,
                            label: maxQuantity + " - " +key.name + " " + key.sku,
                            box_type: key.attribute_value.trim(),
                            available_quantity: maxQuantity,
                            available_uv_quantity: key.available_uv_quantity,
                            available_drill_quantity: key.available_drill_quantity,
                            color: key.enclosure_color,
                            product_id: key.product_id,
                            is_predrill: key.is_predrill
                        });
                    }
                    return results;
                }, [])
                if (enclosure_products.length === 1) {
                    enclosureSku = enclosure_products[0].value;
                    boxType = enclosure_products[0].box_type;
                    enclosureSkulabel = enclosure_products[0].label;
                }
                let availableQuantities = [maxQuantity]
                // for (let i = 1; i <= maxQuantity; i++) {
                //     availableQuantities.push(i);
                // }

                let drill_products = []
                if (result && result.drill_products && result.drill_products.length > 0) {
                    drill_products = result.drill_products.reduce((results, key) => {
                        if (key.available_quantity > 0) {
                            results.push({
                                value: key.sku,
                                label: key.available_quantity + " - " +key.name + " " + key.sku,
                                available_quantity: key.available_quantity,
                                box_type: key.attribute_value.trim(),
                                has_lid: key.has_lid
                            });
                        }
                        return results;
                    }, [])
                }

                if (drill_products.length === 1) {
                    drillSku = drill_products[0].value;
                    drillSkulabel = drill_products[0].label;
                    // if (drill_products[0].box_type === boxType) {
                    //     availableDrillProducts.push(drill_products[0])
                    // }
                } else {
                    drillSku = 0
                    drillSkulabel = "No Drill Products available"
                }
                let uv_products = []
                if (result && result.uv_products && result.uv_products.length > 0) {
                    uv_products = result.uv_products.reduce((results, key) => {
                        let availableQuantity = key.available_quantity;
                        if (key.available_quantity === 0) {
                            availableQuantity = key.qty - key.used_uv_quantity
                        }
                        if (availableQuantity> 0) {
                            results.push({
                                value: key.sku,
                                label: availableQuantity + " - " +key.name + " " + key.sku + " Side: " + key.box_side,
                                available_quantity: availableQuantity,
                                box_type: key.attribute_value.trim(),
                                box_side: key.box_side
                            });
                        }
                        return results;
                    }, [])
                }

                if (uv_products.length === 1) {
                    // uvSku = uv_products[0].value
                    // uvSkulabel = uv_products[0].label
                    if (uv_products[0].box_type === boxType) {
                        availableUvProducts.push(uv_products[0])
                        this.getBoxUvDesigns(boxType)
                    }
                } else if (uv_products.length > 1) {
                    availableUvProducts = uv_products.reduce((results, key) => {
                        if (key.box_type === boxType) {
                            results.push(key);
                        }
                        return results;
                    }, [])
                    this.getBoxUvDesigns(boxType)
                }
                let color_products = []
                let colorCollapse = false;
                let colorSkuQty = 0;
                if (result && result.color_products && result.color_products.length > 0) {
                    color_products = result.color_products.reduce((results, key) => {
                        // console.log('key1', key)
                        if (key.available_quantity > 0) {
                            colorSkuQty += key.available_quantity;
                            results.push({
                                value: key.sku,
                                label: key.available_quantity + " - " +key.name + " " + key.sku,
                                // box_type: key.attribute_value,
                                available_quantity: key.available_quantity,
                                product_id: key.product_id,
                                service_type: 'color'
                            });
                        }
                        return results;
                    }, [])
                    if (color_products.length > 0 ) {
                        colorCollapse = true;
                    }
                }

                color_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS ORDER.", service_type: 'color' })

                let white_products = []
                let whiteCollapse = false;
                let whiteSkuQty = 0;
                if (result && result.white_products && result.white_products.length > 0) {
                    white_products = result.white_products.reduce((results, key) => {
                        // console.log('key1', key)
                        if (key.available_quantity > 0) {
                            whiteSkuQty += key.available_quantity;
                            results.push({
                                value: key.sku,
                                label: key.available_quantity + " - " +key.name + " " + key.sku,
                                // box_type: key.attribute_value,
                                available_quantity: key.available_quantity,
                                product_id: key.product_id,
                                service_type: 'white'
                            });
                        }
                        return results;
                    }, [])

                    if (white_products.length > 0 ) {
                        whiteCollapse = true;
                    }
                }

                white_products.push({ value: 0, label: "NO ADDITIONAL SERVICE NEEDED FOR THIS ORDER.", service_type: 'white'})

                let gloss_products = []
                let glossCollapse = false;
                let glossSkuQty = 0;
                if (result && result.gloss_products && result.gloss_products.length > 0) {
                    gloss_products = result.gloss_products.reduce((results, key) => {
                        // console.log('key1', key)
                        if (key.available_quantity > 0) {
                            glossSkuQty += key.available_quantity;
                            results.push({
                                value: key.sku,
                                label: key.available_quantity + " - " +key.name + " " + key.sku,
                                // box_type: key.attribute_value.trim(),
                                available_quantity: key.available_quantity,
                                product_id: key.product_id,
                                service_type: 'gloss'
                            });
                        }
                        return results;
                    }, [])

                    if (gloss_products.length > 0 ) {
                        glossCollapse = true;
                    }
                }

                gloss_products.push({ value: 0, label: "NO GLOSS SERVICE NEEDED FOR THIS JOB.", service_type: 'gloss'})

                let drill_hole_quantity = this.state.drillHoleQuantity;
                if (result && result.drill_hole_quantity && result.drill_hole_quantity > 0) {
                    drill_hole_quantity = result.drill_hole_quantity;
                }

                this.setState({
                    enclosures: enclosure_products,
                    enclosureSku: enclosureSku,
                    enclosureSkulabel: enclosureSkulabel,
                    boxType: boxType,
                    drillSku: drillSku,
                    drillSkulabel: drillSkulabel,
                    uvSku: uvSku,
                    uvSkulabel: uvSkulabel,
                    availableQuantities: availableQuantities,
                    quantity: maxQuantity,
                    allDrillProducts: drill_products,
                    allUvProducts: uv_products,
                    // drillSkus: availableDrillProducts,
                    uvSkus: availableUvProducts,
                    drillHoleQuantity: drill_hole_quantity,
                    glossProducts: gloss_products,
                    colorProducts: color_products,
                    whiteProducts: white_products,
                    colorCollapse: colorCollapse,
                    whiteCollapse: whiteCollapse,
                    glossCollapse: glossCollapse,
                    whiteSkuQty: whiteSkuQty,
                    colorSkuQty: colorSkuQty,
                    glossSkuQty: glossSkuQty
                })

                this.setUvBoxSideProducts(boxType);
            }

            this.setState({
                loading: false,
                order: result
            })


        }else{
            this.setState({
                loading: false
            }, () => {
                if(result["message"]) alert(result.message);
                const history = createBrowserHistory();
                history.push({ pathname: "/dashboard" })
                window.location.reload();
            })
        }
    }

    getBoxDesigns = async (box_type) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_designs?enclosure_type=' + box_type, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });

        // console.log("RESULT", result.box_designs)
        if (result.box_designs.length > 0) {
            let boxDesigns = result.box_designs.map((design) =>{
                if (design.hole_count <= this.state.drillHoleQuantity) {
                    return {value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, hole_count: design.hole_count, has_lid: design.has_lid }
                } else {
                    return {value: 0, label: design.enclosure_type + " - " + design.name + " - Hole Count: "+ design.hole_count, box_type: design.enclosure_type, hole_count: design.hole_count, has_lid: design.has_lid }
                }
            })
            this.setState({boxDesigns: boxDesigns, availableBoxDesigns: boxDesigns})
        } else {
            let boxDesigns  = [{ value: 0, label: "THERE ARE NOT ANY DRILL TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            this.setState({boxDesigns: boxDesigns, availableBoxDesigns: boxDesigns})
        }
    }

    getBoxUvDesigns = async (box_type) => {
        let token = JSON.parse(localStorage.getItem("token"));
        var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs?enclosure_type=' + box_type, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch((error) => {
            return error;
        });

        // console.log("RESULT", result.box_uv_designs)
        if (result.box_uv_designs.length > 0) {
            let boxUvDesignsA = result.box_uv_designs.reduce((boxes, design) =>{
                if (design.box_side === "A") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            let boxUvDesignsB = result.box_uv_designs.reduce((boxes,design) =>{
                if (design.box_side === "B") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            let boxUvDesignsC = result.box_uv_designs.reduce((boxes, design) =>{
                if (design.box_side === "C") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            let boxUvDesignsD = result.box_uv_designs.reduce((boxes, design) =>{
                if (design.box_side === "D") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            let boxUvDesignsE = result.box_uv_designs.reduce((boxes, design) =>{
                if (design.box_side === "E") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            let boxUvDesignsLid = result.box_uv_designs.reduce((boxes, design) =>{
                if (design.box_side === "Lid") {
                    boxes.push({
                        value: design.id, label: design.enclosure_type + " - " + design.name, box_type: design.enclosure_type, box_side: design.box_side,
                        color_layer: design.color_layer, white_layer: design.white_layer, gloss_layer: design.gloss_layer
                    })
                }
                return boxes;
            }, []);
            this.setState({boxUvDesignsA: boxUvDesignsA, boxUvDesignsB: boxUvDesignsB, boxUvDesignsC: boxUvDesignsC, boxUvDesignsD: boxUvDesignsD, boxUvDesignsE: boxUvDesignsE, boxUvDesignsLid: boxUvDesignsLid})
        } else {
            let boxUvDesignsA  = [{ value: 0, label: "THERE ARE NOT ANY SIDE A UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            let boxUvDesignsB  = [{ value: 0, label: "THERE ARE NOT ANY SIDE B UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            let boxUvDesignsC  = [{ value: 0, label: "THERE ARE NOT ANY SIDE C UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            let boxUvDesignsD  = [{ value: 0, label: "THERE ARE NOT ANY SIDE D UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            let boxUvDesignsE  = [{ value: 0, label: "THERE ARE NOT ANY SIDE E UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            let boxUvDesignsLid  = [{ value: 0, label: "THERE ARE NOT ANY LID UV TEMPLATES TO MATCH THIS ENCLOSURE TYPE."}]
            this.setState({boxUvDesignsA: boxUvDesignsA, boxUvDesignsB: boxUvDesignsB, boxUvDesignsC: boxUvDesignsC, boxUvDesignsD: boxUvDesignsD, boxUvDesignsE: boxUvDesignsE, boxUvDesignsLid: boxUvDesignsLid})
        }
    }

    checkBoxDesigns = (id) => {
        let drillProduct = this.state.drillSkus.filter((sku) => sku.value === id);
        console.log('drillProduct', drillProduct[0]);
        let availableBoxDesigns = this.state.boxDesigns.reduce((result, key) => {
            if (drillProduct[0].has_lid) {
                result.push(key);
            } else if (!key.has_lid) {
                result.push(key);
            }
            return result;
        }, []);

        this.setState({ availableBoxDesigns: availableBoxDesigns});

    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let target = e.target;
        let msg = this.validateFormValues(e.target);
        let errors = this.state.errors

        let isValid = this.validateForm(this.state.errors)

        if(isValid && msg === '') {
            errors.form = false
            this.setState({errors})

            this.setState({ loading: true})

            let box_job_id = this.props.existingBoxJobId ? this.props.existingBoxJobId : null

            let bodyParams = null;

            bodyParams = JSON.stringify({
                // enclosure_type: target.boxType.value.trim(),
                order_number: this.state.order.order_number,
                quantity: target.quantity.value,
                enclosure_sku: target.enclosureSku.value.trim(),
                drill_sku: target.drillSku.value.trim(),
                uv_sku_a: (this.state.uvCollapseA ? target.uvSkuA.value.trim() : 0),
                uv_sku_b: (this.state.uvCollapseB ? target.uvSkuB.value.trim() : 0),
                uv_sku_c: (this.state.uvCollapseC ? target.uvSkuC.value.trim() : 0),
                uv_sku_d: (this.state.uvCollapseD ? target.uvSkuD.value.trim() : 0),
                uv_sku_e: (this.state.uvCollapseE ? target.uvSkuE.value.trim() : 0),
                uv_sku_lid: (this.state.uvCollapseLid ? target.uvSkuLid.value.trim() : 0),
                box_design_id: target.boxDesign.value.trim(),
                box_uv_design_a_id: target.boxUvDesignA.value.trim(),
                box_uv_design_b_id: target.boxUvDesignB.value.trim(),
                box_uv_design_c_id: target.boxUvDesignC.value.trim(),
                box_uv_design_d_id: target.boxUvDesignD.value.trim(),
                box_uv_design_e_id: target.boxUvDesignE.value.trim(),
                box_uv_design_lid_id: target.boxUvDesignLid.value.trim(),
                color_skua: (this.state.colorCollapseA ? target.colorSkuA.value.trim() : 0),
                white_skua: (this.state.whiteCollapseA ? target.whiteSkuA.value.trim() : 0),
                gloss_skua: (this.state.glossCollapseA ? target.glossSkuA.value.trim() : 0),
                color_skub: (this.state.colorCollapseB ? target.colorSkuB.value.trim() : 0),
                white_skub: (this.state.whiteCollapseB ? target.whiteSkuB.value.trim() : 0),
                gloss_skub: (this.state.glossCollapseB ? target.glossSkuB.value.trim() : 0),
                color_skuc: (this.state.colorCollapseC ? target.colorSkuC.value.trim() : 0),
                white_skuc: (this.state.whiteCollapseC ? target.whiteSkuC.value.trim() : 0),
                gloss_skuc: (this.state.glossCollapseC ? target.glossSkuC.value.trim() : 0),
                color_skud: (this.state.colorCollapseD ? target.colorSkuD.value.trim() : 0),
                white_skud: (this.state.whiteCollapseD ? target.whiteSkuD.value.trim() : 0),
                gloss_skud: (this.state.glossCollapseD ? target.glossSkuD.value.trim() : 0),
                color_skulid: (this.state.colorCollapseLid ? target.colorSkuLid.value.trim() : 0),
                white_skulid: (this.state.whiteCollapseLid ? target.whiteSkuLid.value.trim() : 0),
                gloss_skulid: (this.state.glossCollapseLid ? target.glossSkuLid.value.trim() : 0),
                box_job_id: box_job_id
            })


            this.setState({
                loading: true,
                submitted: 'disabled',
            }, async () => {
                let token = JSON.parse(localStorage.getItem("token"));
                var result = await fetch(process.env.REACT_APP_API_URL + '/api/v4/box_jobs/'+box_job_id, {
                    method:  'PUT',
                    headers: {
                        Authorization: "Bearer " + token,
                        'Content-Type': 'application/json'
                    },
                    body: bodyParams
                })
                .then(response => response.json())
                .then(data => {
                    return data;
                })
                .catch((error) => {
                    return error;
                });
                // console.log('Box Job Post', result);
                this.setState({
                    loading: false
                }, () => {
                    if(result["box_job"]){
                        this.setState({ success: 'Box Job Successfully saved!' })
                        toast.success('Box Job Successfully saved!');
                        const history = createBrowserHistory();
                        history.push({ pathname: "/dashboard" })



                    } else {
                        // console.log('result', result);
                        if (this.state.errors.form !== "" && !result.message.includes('Success')) {
                            // alert(result.message);
                            errors.form = result.message;
                            // target.disabled = false;
                            this.setState({  errors })
                            this.setState({  submitted: '' })
                        } else {
                            this.setState({ success: result.message })
                        }
                    }
                })

            })

        }
    }

    handleErrors = (response) => {
        let errors = this.state.errors
        if (!response.ok) {
            errors.form = "ERROR"
            this.setState({errors})
            // throw Error(response.statusText);
        }
        return response.json();
    }

    validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            // if we have an error string set valid to false
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    validateFormValues = (formObj) => {
        let msg = '';

        if (formObj.enclosureSku.value.trim() === '') {
            msg = 'Please Choose an Enclosure Product';
        } else if (formObj.drillSku.value.trim() === '' ) {
            msg = 'Please Choose a Drill Product';
        // } else if (formObj.uvSku.value.trim() === '' ) {
        //     msg = 'Please Choose a UV Product';
    // } else if (formObj.drillSku.value.trim() !== '' && formObj.drillSku.value.trim() !== "0" && formObj.boxDesign.value.trim() === '' ) {
    //     msg = 'Please Choose a Box Design for your Drill Product';
    } else if (formObj.uvSkuA.value.trim() !== '' && formObj.uvSkuA.value.trim() !== "0" && formObj.boxUvDesignA.value.trim() === '' ) {
        msg = 'Please Choose a Side A UV Design for your UV Product';
    } else if (formObj.uvSkuB.value.trim() !== '' && formObj.uvSkuB.value.trim() !== "0" && formObj.boxUvDesignB.value.trim() === '' ) {
        msg = 'Please Choose a Side B UV Design for your UV Product';
    } else if (formObj.uvSkuC.value.trim() !== '' && formObj.uvSkuC.value.trim() !== "0" && formObj.boxUvDesignC.value.trim() === '' ) {
        msg = 'Please Choose a Side C UV Design for your UV Product';
    } else if (formObj.uvSkuD.value.trim() !== '' && formObj.uvSkuD.value.trim() !== "0" && formObj.boxUvDesignD.value.trim() === '' ) {
        msg = 'Please Choose a Side D UV Design for your UV Product';
    } else if (formObj.uvSkuE.value.trim() !== '' && formObj.uvSkuE.value.trim() !== "0" && formObj.boxUvDesignE.value.trim() === '' ) {
        msg = 'Please Choose a Side E UV Design for your UV Product';
    } else if (formObj.uvSkuLid.value.trim() !== '' && formObj.uvSkuLid.value.trim() !== "0" && formObj.boxUvDesignLid.value.trim() === '' ) {
        msg = 'Please Choose a UV Lid Design for your UV Product';
        } else if (formObj.doubleCheckTerms.checked !== true) {
            msg = 'Please agree to terms by  double checking your design and check the checkbox';
        }

        let errors = this.state.errors
        errors.form = msg
        this.setState({errors})

        return msg;
    }

    validateFormFields = (name, value) => {
        let errors = this.state.errors;
        switch (name) {

            case 'enclosureSku':
                errors.enclosureSku = value.length === 0 ? 'Cannot be Blank' : '';
                break;

            default:
                break;
        }

        return errors;
    }

    handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;
            let errors =   this.validateFormFields(name, value);
            this.setState({errors, [name]: value})
        }
    }

    handleSkuSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;
            let [drillCollapse, uvCollapseADesign, uvCollapseBDesign, uvCollapseCDesign, uvCollapseDDesign, uvCollapseEDesign, uvCollapseLidDesign] = [this.state.drillCollapse, this.state.uvCollapseADesign, this.state.uvCollapseBDesign, this.state.uvCollapseCDesign, this.state.uvCollapseDDesign, this.state.uvCollapseEDesign, this.state.uvCollapseLidDesign]
            if (e.name === "drillSku") {
                drillCollapse = e.value !== 0;
                this.checkBoxDesigns(e.value);
            } else if (e.name === "uvSkuA") {
                uvCollapseADesign = e.value !== 0;
            } else if (e.name === "uvSkuB") {
                uvCollapseBDesign = e.value !== 0;
            } else if (e.name === "uvSkuC") {
                uvCollapseCDesign = e.value !== 0;
            } else if (e.name === "uvSkuD") {
                uvCollapseDDesign = e.value !== 0;
            } else if (e.name === "uvSkuE") {
                uvCollapseEDesign = e.value !== 0;
            } else if (e.name === "uvSkuLid") {
                uvCollapseLidDesign = e.value !== 0;
            }
            this.setState({[name]: value, [name+"label"]: label,
                drillCollapse: drillCollapse,
                uvCollapseADesign: uvCollapseADesign,
                uvCollapseBDesign: uvCollapseBDesign,
                uvCollapseCDesign: uvCollapseCDesign,
                uvCollapseDDesign: uvCollapseDDesign,
                uvCollapseEDesign: uvCollapseEDesign,
                uvCollapseLidDesign: uvCollapseLidDesign
            })
        }
    }

    handleUvSelectChange = (e) => {
        if (e) {
            console.log("e", e);
            const {name, value, label } = e;
            const {white_layer, color_layer, gloss_layer, box_side } = e;

            let whiteSkuQty = this.getServicesQty("white");
            let colorSkuQty = this.getServicesQty("color");
            let glossSkuQty = this.getServicesQty("gloss");

            const whiteCollapse =  `whiteCollapse${box_side}`
            const whiteCollapseVal = whiteSkuQty >= this.state.quantity && this.state.whiteCollapse && white_layer === "Yes" ? true : false
            const colorCollapse =  `colorCollapse${box_side}`
            const colorCollapseVal = colorSkuQty >= this.state.quantity && this.state.colorCollapse && color_layer === "Yes" ? true : false
            const glossCollapse =  `glossCollapse${box_side}`
            const glossCollapseVal = glossSkuQty >= this.state.quantity && this.state.glossCollapse && gloss_layer === "Yes" ? true : false
            // console.log("whiteCollapseVal", whiteCollapseVal);
            // console.log("colorCollapseVal", colorCollapseVal);
            // console.log("glossCollapseVal", glossCollapseVal);

            // console.log('state_white', this.state.whiteCollapse);
            // console.log('state_color', this.state.colorCollapse);
            // console.log('state_gloss', this.state.glossCollapse);

            // reset sku values
            if (whiteCollapseVal === false) {
                const whiteSku = `whiteSku${box_side}`
                const whiteSkulabel = `whiteSku${box_side}label`
                this.setState({[whiteSku]: 0, [whiteSkulabel]: ""})
            }
            if (colorCollapseVal === false) {
                const colorSku = `colorSku${box_side}`
                const colorSkulabel = `colorSku${box_side}label`
                this.setState({[colorSku]: 0, [colorSkulabel]: ""})
            }
            if (glossCollapseVal === false) {
                const glossSku = `glossSku${box_side}`
                const glossSkulabel = `glossSku${box_side}label`
                this.setState({[glossSku]: 0, [glossSkulabel]: ""})
            }

            this.setState({
                [name]: value, [name+"label"]: label,
                [whiteCollapse]: whiteCollapseVal,
                [colorCollapse]: colorCollapseVal,
                [glossCollapse]: glossCollapseVal
            })
        }
    }

    handleSelectChange = (e) => {
        if (e) {
            // console.log("e", e);
            const {name, value, label } = e;

            this.setState({[name]: value, [name+"label"]: label})
        }
    }

    getServicesQty = (service_type) => {
        let service_types = ["A", "B", "C", "D", "E", "Lid"];
        let totalQty = 0;
        let serviceQtyName = `${service_type}SkuQty`;
        // console.log("serviceQtyName", serviceQtyName);
        let serviceQty = this.state[serviceQtyName];

        service_types.forEach((type) => {
            totalQty += this.state[`${service_type}Sku${type}`] && this.state[`${service_type}Sku${type}`] !== 0 ? this.state.quantity : 0;
        });
        return +serviceQty - +totalQty;
    }

    handleQuantityChange = (e) => {
        if (e) {

            // console.log("e", e);
            const {name, value, label } = e;
            let uvCollapseA = this.state.uvCollapseA;
            this.state.uvSkusA.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseA = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseA = true;
                }
            })
            let uvCollapseB = this.state.uvCollapseB;
            this.state.uvSkusB.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseB = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseB = true;
                }
            })
            let uvCollapseC = this.state.uvCollapseC;
            this.state.uvSkusC.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseC = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseC = true;
                }
            })
            let uvCollapseD = this.state.uvCollapseD;
            this.state.uvSkusD.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseD = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseD = true;
                }
            })
            let uvCollapseE = this.state.uvCollapseE;
            this.state.uvSkusE.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseE = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseE = true;
                }
            })
            let uvCollapseLid = this.state.uvCollapseLid;
            this.state.uvSkusLid.forEach((uvSku, index) =>{
                if (uvSku.available_quantity < value) {
                    uvCollapseLid = false;
                } else if (uvSku.available_quantity >= value) {
                    uvCollapseLid = true;
                }
            })

            let currentQuantity = this.state.quantity;
            if (value > currentQuantity) {
                this.resetFormItems();
                }
            // let colorCollapse = this.state.colorCollapse;
            // this.state.colorProducts.forEach((uvSku, index) =>{
            //     if (uvSku.available_quantity < value) {
            //         colorCollapse = false;
            //     } else if (uvSku.available_quantity >= value) {
            //         colorCollapse = true;
            //     }
            // })
            // let glossCollapse = this.state.glossCollapse;
            // this.state.glossProducts.forEach((uvSku, index) =>{
            //     if (uvSku.available_quantity < value) {
            //         glossCollapse = false;
            //     } else if (uvSku.available_quantity >= value) {
            //         glossCollapse = true;
            //     }
            // })
            // let whiteCollapse = this.state.whiteCollapse;
            // this.state.whiteProducts.forEach((uvSku, index) =>{
            //     if (uvSku.available_quantity < value) {
            //         whiteCollapse = false;
            //     } else if (uvSku.available_quantity >= value) {
            //         whiteCollapse = true;
            //     }
            // })

            this.setState({[name]: value, [name+"label"]: label,
                uvCollapseA: uvCollapseA,
                uvCollapseB: uvCollapseB,
                uvCollapseC: uvCollapseC,
                uvCollapseD: uvCollapseD,
                uvCollapseE: uvCollapseE,
                uvCollapseLid: uvCollapseLid,
                // colorCollapse: colorCollapse,
                // glossCollapse: glossCollapse,
                // whiteCollapse: whiteCollapse,
            })
        }
    }

    resetFormItems = () => {
        // let drillFields = ["drillSku", "drillSkulabel", "boxDesign", "boxDesignlabel"]
        let service_types = ["A", "B", "C", "D", "E", "Lid"];
        let uvFields = ["uvSku", "boxUvDesign", "colorSku", "whiteSku", "glossSku"];

        this.setState({
            drillSku: 0, drillSkulabel: "Select Drill Product", boxDesign: "", boxDesignlabel: "Select Design",
        })
        service_types.forEach((type) => {
            uvFields.forEach((field) => {
                let productType = field.includes("Design") ? "UV Design" : field.includes("color") ? "Color" : field.includes("white") ? "White" : field.includes("gloss") ? "Gloss" : "UV Product";

                let label = `Select Side ${type} ${productType}`;
                this.setState({
                    [`${field}${type}`]: 0, [`${field}${type}label`]: label
                })
            })
        })
    }

    handleDrillSelectChange = (e) => {
        if (e) {
            //console.log("e", e);
            const {name, value, label, holeCount } = e;
            let errors = this.state.errors
            if (holeCount <= this.state.drillHoleQuantity) {
                errors.drillError = ''
                this.setState({[name]: value, [name+"label"]: label, errors: errors})
            } else {
                errors.drillError = "Maximum " + this.state.drillHoleQuantity + " Holes allowed for this order. Please choose another design or create a new design with fewer holes.";
                this.setState({errors})
            }
        }
    }

    handleTypeChange = (e) => {
        if (e) {
            // console.log(e);
            const {name, value, label} = e;
            if (e.available_quantity != undefined) {
                let availableQuantities = []
                for (let i = 1; i <= e.available_quantity; i++) {
                    availableQuantities.push(i);
                }
                this.setState({quantity: 1, availableQuantities: availableQuantities})
            }
            // this.setState({ drillSku: 0, drillSkulabel: "Select Drill Product", boxDesign: "", boxDesignlabel: "Select Design",
            //                 uvSkuA: 0, uvSkuAlabel: "Select Side A UV Product", uvSkuB: 0, uvSkuBlabel: "Select Side B UV Product",
            //                 uvSkuC: 0, uvSkuClabel: "Select Side C UV Product", uvSkuD: 0, uvSkuDlabel: "Select Side D UV Product",
            //                 uvSkuE: 0, uvSkuElabel: "Select Side E UV Product", uvSkuLid: 0, uvSkuLidlabel: "Select Lid UV Product",
            //                 boxUvDesignA: "", boxUvDesignAlabel: "Select Side A UV Design", boxUvDesignB: "", boxUvDesignBlabel: "Select Side B UV Design",
            //                 boxUvDesignC: "", boxUvDesignClabel: "Select Side C UV Design", boxUvDesignD: "", boxUvDesignDlabel: "Select Side D UV Design",
            //                 boxUvDesignE: "", boxUvDesignElabel: "Select Side E UV Design", boxUvDesignLid: "", boxUvDesignLidlabel: "Select Lid UV Design",
            //             })
            this.resetFormItems();

            let currentDrillProducts = this.state.allDrillProducts
            // console.log('cDP', currentDrillProducts);
            let availableDrillProducts = []
            if (e.is_predrill !== "yes" && currentDrillProducts.length > 0) {
                // let isCombo = false;
                // this.setState({ drillSku: "", drillSkulabel: "" })
                availableDrillProducts  = currentDrillProducts.reduce((result, key) => {
                    // console.log('key', key)
                    if (key && key.box_type === e.box_type) {
                        result.push({
                            value: key.value,
                            label: key.label,
                            box_type: key.box_type,
                            color: key.color,
                            available_quantity: key.available_quantity,
                            product_id: key.product_id,
                            has_lid: key.has_lid
                        })
                    }
                    return result;
                }, [])

                this.getBoxDesigns(e.box_type)

            }
            if (availableDrillProducts.length === 0) {
                availableDrillProducts = [{value: 0, label: "No Drill Products available"}]
            } else {
                availableDrillProducts.push({ value: 0, label: "NO DRILL SERVICE NEEDED FOR THIS JOB."})
            }

            // if (availableUvProducts.length === 0) {
            //     availableUvProducts = [{value: 0, label: "No Uv Products available"}]
            // } else {
            //     availableUvProducts.push({ value: 0, label: "NO UV SERVICE NEEDED FOR THIS JOB."})
            // }

            this.setUvBoxSideProducts(e.box_type);

            this.setState({
                [name]: value, [name+"label"]: label,
                drillSkus: availableDrillProducts,
                boxType: e.box_type,
                color: e.color
            })

            this.props.handleBoxTypeChangeSelect(e.box_type);
        }
    }

    setUvBoxSideProducts = (boxType) => {
        let currentUvProducts = this.state.allUvProducts

        let [availableUvAProducts, availableUvBProducts, availableUvCProducts, availableUvDProducts, availableUvEProducts, availableUvLidProducts] = [[], [], [], [], [], []];
        let [uvCollapseA, uvCollapseB, uvCollapseC, uvCollapseD, uvCollapseE, uvCollapseLid] = [false, false, false, false, false, false];
        if (currentUvProducts.length > 0) {
            // let isCombo = false;
            availableUvAProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "A") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvAProducts.length !== 0) {
                availableUvAProducts.push({ value: 0, label: "NO UV A SIDE SERVICE NEEDED FOR THIS JOB."})
            }
            availableUvBProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "B") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvBProducts.length !== 0) {
                availableUvBProducts.push({ value: 0, label: "NO UV B SIDE SERVICE NEEDED FOR THIS JOB."})
            }
            availableUvCProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "C") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvCProducts.length !== 0) {
                availableUvCProducts.push({ value: 0, label: "NO UV C SIDE SERVICE NEEDED FOR THIS JOB."})
            }
            availableUvDProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "D") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvDProducts.length !== 0) {
                availableUvDProducts.push({ value: 0, label: "NO UV D SIDE SERVICE NEEDED FOR THIS JOB."})
            }
            availableUvEProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "E") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvEProducts.length !== 0) {
                availableUvEProducts.push({ value: 0, label: "NO UV E SIDE SERVICE NEEDED FOR THIS JOB."})
            }
            availableUvLidProducts  = currentUvProducts.reduce((result, key) => {
                // console.log('key', key)
                if (key && key.box_type === boxType && key.box_side === "Lid") {
                    result.push({ value: key.value, label: key.label, box_type: key.box_type, color: key.color, available_quantity: key.available_quantity, product_id: key.product_id, box_side: key.box_side  })
                }
                return result;
            }, []);
            if (availableUvLidProducts.length !== 0) {
                availableUvLidProducts.push({ value: 0, label: "NO UV LID SIDE SERVICE NEEDED FOR THIS JOB."})
            }

            // console.log('availableUvProducts',availableUvProducts);
            this.getBoxUvDesigns(boxType)
        }
        if (availableUvAProducts.length > 0) {
            uvCollapseA = true;
        }
        if (availableUvBProducts.length > 0) {
            uvCollapseB = true;
        }
        if (availableUvCProducts.length > 0) {
            uvCollapseC = true;
        }
        if (availableUvDProducts.length > 0) {
            uvCollapseD = true;
        }
        if (availableUvEProducts.length > 0) {
            uvCollapseE = true;
        }
        if (availableUvLidProducts.length > 0) {
            uvCollapseLid = true;
        }
        this.setState({
            uvSkusA: availableUvAProducts,
            uvSkusB: availableUvBProducts,
            uvSkusC: availableUvCProducts,
            uvSkusD: availableUvDProducts,
            uvSkusE: availableUvEProducts,
            uvSkusLid: availableUvLidProducts,
            uvCollapseA: uvCollapseA,
            uvCollapseB: uvCollapseB,
            uvCollapseC: uvCollapseC,
            uvCollapseD: uvCollapseD,
            uvCollapseE: uvCollapseE,
            uvCollapseLid: uvCollapseLid,
        })
    }

    toggle = (e) => {
        if (this.state.collapse === false) {
            this.setState({ collapse: true, collapseText: "Expand"})
        } else {
            this.setState({ collapse: false, collapseText: "Collapse"})
        }
    }

    render() {
        return (
            <Container className="m-4">
                <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover={false}
                />

                <Alert color="success" isOpen={this.state.success ? true : false} className="text-center">
                    { this.state.success } <br />
                    <a className="btn btn-success m-4" href="/dashboard"> Back To Dashboard</a>
                </Alert>

                {!this.state.success && (
                    <form action="" className="edit-design" onSubmit={this.handleSubmit}>

                        <h3>Configure Box Job for Order #{this.state.order.order_number}</h3>
                        <Alert color="warning">
                            <div>For best price and fastest delivery, all jobs are completed exactly as submitted.</div>
                            <div>There is NO CHANCE TO MODIFY A JOB AFTER IT IS SUBMITTED. </div>
                            <div>Please fill this form very carefully and review your choices. Whatever you submit here will be IRREVERSIBLE.</div>
                        </Alert>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <label className="col-form-label">Enclosure Product</label>

                                <Select
                                    key="enclosureSku"
                                    name="enclosureSku"
                                    isClearable={false}
                                    value={{ value: this.state.enclosureSku, label: this.state.enclosureSkulabel}}
                                    // onChange={this.handleTypeChange}
                                    options={this.state.enclosures.map((t) => ({ value: t.value, label: t.label, name: "enclosureSku", box_type: t.box_type,  color: t.color, available_quantity: t.available_quantity, is_predrill: t.is_predrill }))}
                                />
                                <Alert color="warning" isOpen={this.state.errors.enclosureSku ? true : false}>{this.state.errors.enclosureSku}</Alert>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col className="col" md={4}>
                                <label className="col-form-label">Quantity</label>
                                <Select
                                    key="quantity"
                                    name="quantity"
                                    isClearable={false}
                                    value={{ value: this.state.quantity, label: this.state.quantity}}
                                    // onChange={this.handleSelectChange}
                                    options={this.state.availableQuantities.map((t) => ({ value: t, label: t, name: "quantity" }))}
                                />
                            </Col>
                            <Col className="col" md={8}>
                                <label className="col-form-label">Enclosure Type</label>
                                <Input readOnly key="boxType" id="boxType" name="boxType" placeholder="Box Type" value={this.state.boxType} />

                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col className="col" md={12}>
                                <label className="col-form-label">Drill Product</label>
                                <Select
                                    key="drillSku"
                                    name="drillSku"
                                    isClearable={false}
                                    value={{ value: this.state.drillSku, label: this.state.drillSkulabel}}
                                    onChange={this.handleSkuSelectChange}
                                    options={this.state.drillSkus.map((t) => ({ value: t.value, label: t.label, name: "drillSku", box_type: t.box_type }))}
                                />
                                <Alert color="warning" isOpen={this.state.errors.drillSku ? true : false}>{this.state.errors.drillSku}</Alert>
                            </Col>
                        </FormGroup>

                        <Collapse isOpen={this.state.drillCollapse === true}>

                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">My Drill Designs (Max Holes: {this.state.drillHoleQuantity})</label>
                                    <Select
                                        key="boxDesign"
                                        name="boxDesign"
                                        isClearable={false}
                                        onChange={this.handleDrillSelectChange}
                                        value={{ value: this.state.boxDesign, label: this.state.boxDesignlabel}}
                                        options={this.state.availableBoxDesigns.map((t) => ({ value: t.value, label: t.label, name: "boxDesign", holeCount: t.hole_count }))}
                                    />
                                    <Alert color="danger" className="mt-1" isOpen={this.state.errors.drillError ? true : false}>{this.state.errors.drillError}</Alert>
                                </Col>
                            </FormGroup>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseA === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Side A Uv Product</label>
                                    <Select
                                        key="uvSkuA"
                                        name="uvSkuA"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuA, label: this.state.uvSkuAlabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusA.map((t) => ({ value: t.value, label: t.label, name: "uvSkuA", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuA ? true : false}>{this.state.errors.uvSkuA}</Alert>
                                </Col>
                            </FormGroup>

                            <Collapse isOpen={this.state.uvCollapseADesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Side A / Face Uv Designs</label>
                                        <a key="addUVDesignA" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                        <Select
                                            key="boxUvDesignA"
                                            name="boxUvDesignA"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignA, label: this.state.boxUvDesignAlabel}}
                                            options={this.state.boxUvDesignsA.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignA", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseA === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                        <Select
                                            key="colorSkuA"
                                            name="colorSkuA"
                                            isClearable={false}
                                            value={{ value: this.state.colorSkuA, label: this.state.colorSkuAlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuA", service_type: t.service_type }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseA === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                        <Select
                                            key="whiteSkuA"
                                            name="whiteSkuA"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSkuA, label: this.state.whiteSkuAlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuA", service_type: t.service_type }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.glossCollapseA === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                            <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                            <Select
                                                key="glossSkuA"
                                                name="glossSkuA"
                                                isClearable={false}
                                                value={{ value: this.state.glossSkuA, label: this.state.glossSkuAlabel}}
                                                onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuA", service_type: t.service_type }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <hr className='mb-0'/>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseB === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Side B Uv Product</label>
                                    <Select
                                        key="uvSkuB"
                                        name="uvSkuB"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuB, label: this.state.uvSkuBlabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusB.map((t) => ({ value: t.value, label: t.label, name: "uvSkuB", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuB ? true : false}>{this.state.errors.uvSkuB}</Alert>
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.uvCollapseBDesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Side B / Top Uv Designs</label>
                                        <a key="addUVDesignB" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                        <Select
                                            key="boxUvDesignB"
                                            name="boxUvDesignB"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignB, label: this.state.boxUvDesignBlabel}}
                                            options={this.state.boxUvDesignsB.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignB", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseB === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                        <Select
                                            key="colorSkuB"
                                            name="colorSkuB"
                                            isClearable={false}
                                            value={{ value: this.state.colorSkuB, label: this.state.colorSkuBlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuB" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseB === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                        <Select
                                            key="whiteSkuB"
                                            name="whiteSkuB"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSkuB, label: this.state.whiteSkuBlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuB" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.glossCollapseB === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                            <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                            <Select
                                                key="glossSkuB"
                                                name="glossSkuB"
                                                isClearable={false}
                                                value={{ value: this.state.glossSkuB, label: this.state.glossSkuBlabel}}
                                                onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuB" }))}
                                            />
                                        </Col>
                                    </FormGroup>
                            </Collapse>
                            <hr className='mb-0'/>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseC === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Side C Uv Product</label>
                                    <Select
                                        key="uvSkuC"
                                        name="uvSkuC"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuC, label: this.state.uvSkuClabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusC.map((t) => ({ value: t.value, label: t.label, name: "uvSkuC", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuC ? true : false}>{this.state.errors.uvSkuC}</Alert>
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.uvCollapseCDesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Side C / Left Uv Designs</label>
                                        <a key="addUVDesignC" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                        <Select
                                            key="boxUvDesignC"
                                            name="boxUvDesignC"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignC, label: this.state.boxUvDesignClabel}}
                                            options={this.state.boxUvDesignsC.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignC", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseC === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                        <Select
                                            key="colorSkuC"
                                            name="colorSkuC"
                                            isClearable={false}
                                            value={{ value: this.state.colorSkuC, label: this.state.colorSkuClabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuC" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseC === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                        <Select
                                            key="whiteSkuC"
                                            name="whiteSkuC"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSkuC, label: this.state.whiteSkuClabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuC" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.glossCollapseC === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                            <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                            <Select
                                                key="glossSkuC"
                                                name="glossSkuC"
                                                isClearable={false}
                                                value={{ value: this.state.glossSkuC, label: this.state.glossSkuClabel}}
                                                onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuC" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <hr className='mb-0'/>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseD === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Side D Uv Product</label>
                                    <Select
                                        key="uvSkuD"
                                        name="uvSkuD"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuD, label: this.state.uvSkuDlabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusD.map((t) => ({ value: t.value, label: t.label, name: "uvSkuD", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuD ? true : false}>{this.state.errors.uvSkuD}</Alert>
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.uvCollapseDDesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Side D / Bottom Uv Designs</label>
                                        <a key="addUVDesignD" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                        <Select
                                            key="boxUvDesignD"
                                            name="boxUvDesignD"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignD, label: this.state.boxUvDesignDlabel}}
                                            options={this.state.boxUvDesignsD.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignD", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseD === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                        <Select
                                            key="colorSkuD"
                                            name="colorSkuD"
                                            isClearable={false}
                                            value={{ value: this.state.colorSkuD, label: this.state.colorSkuDlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuD" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseD === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                        <Select
                                            key="whiteSkuD"
                                            name="whiteSkuD"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSkuD, label: this.state.whiteSkuDlabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuD" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.glossCollapseD === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                            <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                            <Select
                                                key="glossSkuD"
                                                name="glossSkuD"
                                                isClearable={false}
                                                value={{ value: this.state.glossSkuD, label: this.state.glossSkuDlabel}}
                                                onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuD" }))}
                                            />
                                        </Col>
                                    </FormGroup>
                            </Collapse>
                            <hr className='mb-0'/>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseE === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Side E Uv Product</label>
                                    <Select
                                        key="uvSkuE"
                                        name="uvSkuE"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuE, label: this.state.uvSkuElabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusE.map((t) => ({ value: t.value, label: t.label, name: "uvSkuE", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuE ? true : false}>{this.state.errors.uvSkuE}</Alert>
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.uvCollapseEDesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Side E / Right Uv Designs</label>
                                        <a key="addUVDesignE" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                        <Select
                                            key="boxUvDesignE"
                                            name="boxUvDesignE"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignE, label: this.state.boxUvDesignElabel}}
                                            options={this.state.boxUvDesignsE.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignE", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseE === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                        <Select
                                            key="colorSkuE"
                                            name="colorSkuE"
                                            isClearable={false}
                                            value={{ value: this.state.colorSkuE, label: this.state.colorSkuElabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuE" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseE === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                        <Select
                                            key="whiteSkuE"
                                            name="whiteSkuE"
                                            isClearable={false}
                                            value={{ value: this.state.whiteSkuE, label: this.state.whiteSkuElabel}}
                                            onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuE" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.glossCollapseE === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                            <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                            <Select
                                                key="glossSkuE"
                                                name="glossSkuE"
                                                isClearable={false}
                                                value={{ value: this.state.glossSkuE, label: this.state.glossSkuElabel}}
                                                onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuE" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <hr className='mb-0'/>
                        </Collapse>

                        <Collapse isOpen={this.state.uvCollapseLid === true}>
                            <FormGroup row>
                                <Col className="col" md={12}>
                                    <label className="col-form-label">Lid Uv Product</label>
                                    <a key="addUVDesignLid" className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new" target="_blank" title="Add New UV Design">+</a>
                                    <Select
                                        key="uvSkuLid"
                                        name="uvSkuLid"
                                        isClearable={false}
                                        value={{ value: this.state.uvSkuLid, label: this.state.uvSkuLidlabel}}
                                        onChange={this.handleSkuSelectChange}
                                        options={this.state.uvSkusLid.map((t) => ({ value: t.value, label: t.label, name: "uvSkuLid", box_type: t.box_type, box_side: t.box_side }))}
                                    />
                                    <Alert color="warning" isOpen={this.state.errors.uvSkuLid ? true : false}>{this.state.errors.uvSkuLid}</Alert>
                                </Col>
                            </FormGroup>
                            <Collapse isOpen={this.state.uvCollapseLidDesign === true}>
                                <FormGroup row>
                                    <Col className="col" md={12}>
                                        <label className="col-form-label">My Enclosure Lid Uv Designs</label>
                                        <Select
                                            key="boxUvDesignLid"
                                            name="boxUvDesignLid"
                                            isClearable={false}
                                            onChange={this.handleUvSelectChange}
                                            value={{ value: this.state.boxUvDesignLid, label: this.state.boxUvDesignLidlabel}}
                                            options={this.state.boxUvDesignsLid.map((t) => ({ value: t.value, label: t.label, name: "boxUvDesignLid", white_layer: t.white_layer, color_layer: t.color_layer, gloss_layer: t.gloss_layer, box_side: t.box_side }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                            <Collapse isOpen={this.state.colorCollapseLid === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                    <label className="col-form-label">ADDITIONAL SERVICE FOR COLOR LAYER</label>
                                    <Select
                                            key="colorSkuLid"
                                            name="colorSkuLid"
                                        isClearable={false}
                                            value={{ value: this.state.colorSkuLid, label: this.state.colorSkuLidlabel}}
                                        onChange={this.handleSelectChange}
                                            options={this.state.colorProducts.map((t) => ({ value: t.value, label: t.label, name: "colorSkuLid" }))}
                                    />
                                </Col>
                            </FormGroup>
                        </Collapse>

                            <Collapse isOpen={this.state.whiteCollapseLid === true}>
                                <FormGroup row className="pl-4">
                                    <Col className="col pl-4" md={12}>
                                    <label className="col-form-label">ADDITIONAL SERVICE FOR WHITE LAYER</label>
                                    <Select
                                            key="whiteSkuLid"
                                            name="whiteSkuLid"
                                        isClearable={false}
                                            value={{ value: this.state.whiteSkuLid, label: this.state.whiteSkuLidlabel}}
                                        onChange={this.handleSelectChange}
                                            options={this.state.whiteProducts.map((t) => ({ value: t.value, label: t.label, name: "whiteSkuLid" }))}
                                    />
                                </Col>
                            </FormGroup>
                        </Collapse>
                            <Collapse isOpen={this.state.glossCollapseLid === true}>
                                <FormGroup row className="pl-4">
                                        <Col className="col pl-4" md={12}>
                                        <label className="col-form-label">SELECT A GLOSS PRODUCT</label>
                                        <Select
                                                key="glossSkuLid"
                                                name="glossSkuLid"
                                            isClearable={false}
                                                value={{ value: this.state.glossSkuLid, label: this.state.glossSkuLidlabel}}
                                            onChange={this.handleSelectChange}
                                                options={this.state.glossProducts.map((t) => ({ value: t.value, label: t.label, name: "glossSkuLid" }))}
                                        />
                                    </Col>
                                </FormGroup>
                            </Collapse>
                        </Collapse>


                        <FormGroup row>
                            <Col className="col alert alert-secondary" md={12}>
                                <Label for="doubleCheckTerms" check style={{ paddingLeft: '25px', paddingRight: '15px' }}>
                                    <Input type="checkbox" name="doubleCheckTerms" falsevalue="true" />
                                    I double checked and I confirm that everything in this form is correct and I understand that no matter what I won't be able to request or change anything about this order after I click Submit Order button.
                                </Label>
                            </Col>
                        </FormGroup>

                        <FormGroup row>
                            <Col className="col text-right" md={12}>
                                <Alert className="text-left" color="danger" isOpen={this.state.errors.form ? true : false}>{this.state.errors.form}</Alert>
                                <div>
                                    <a href="/dashboard" className="btn btn-sm btn-outline-dark mx-2">Cancel</a>
                                    <Button color="success" type="submit" disabled={this.state.submitted}>Resubmit Box Job</Button>
                                </div>
                            </Col>
                        </FormGroup>
                    </form>
                )}
            </Container>
        );
    }
}

export default ExisitingCustomerBoxJobForm;