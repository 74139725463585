import React from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import { createBrowserHistory } from 'history';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Page from './Layout/Page';
import { useAuth } from "./Auth/auth";

import UploadWrapperV3 from "./BoxUvDesign/UploadWrapperV3";
import BoxWrapperV3 from "./BoxDesign/BoxWrapperV3";
import BoxWrapperSimple from "./BoxDesign/BoxWrapperSimple";
import NotFound from "./Layout/NotFound";

import Login from "./Auth/Login";
import Register from "./Auth/Register";
import PasswordReset from "./Auth/PasswordReset";
import NewPassword from "./Auth/NewPassword";
import AccountConfirmation from "./Auth/AccountConfirmation";

import Dashboard from "./BoxCustomer/Dashboard";
import Archive from "./BoxCustomer/Archive";
import BoxDesignWrapper from "./BoxCustomer/BoxDesignWrapper";
import BoxUvDesignWrapper from "./BoxCustomer/BoxUvDesignWrapper";
import BoxJobWrapper from "./BoxCustomer/BoxJobWrapper";
import FlatDesignWrapper from "./BoxCustomer/FlatDesignWrapper";

const Router = () => {
    const { token } = useAuth();
    if(window.location.pathname === "/"){
        const history = createBrowserHistory();
        history.push({ pathname: token ? '/dashboard' : "/login" })
    }

    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute path="/login" component={Login} title='Login' />
                <PublicRoute path="/register" component={Register} title='Register' />
                {/* <Route path="/login" render={(props) => (<Page title="Login"><Login {...props} /></Page>)}/>
                <Route path="/register" render={(props) => (<Page title="Register"><Register {...props} /></Page>)}/> */}
                <Route path="/password-reset" render={(props) => (<Page title="Password Reset"><PasswordReset {...props} /></Page>)}/>
                <Route path="/new-password" render={(props) => (<Page title="New Password"><NewPassword {...props} /></Page>)}/>
                <Route path="/account-confirmation" render={(props) => (<Page title="Confirm Account"><AccountConfirmation {...props} /></Page>)}/>
                <PrivateRoute path="/dashboard" component={Dashboard} title='Dashboard' />
                <PrivateRoute path="/archive" component={Archive} title='Archive Box Templates' />
                <PrivateRoute path="/box-designs/new" component={BoxDesignWrapper} title='Add Box Drill Design' />
                <PrivateRoute path="/box-designs/edit" component={BoxDesignWrapper} title='Edit Box Drill Design' />
                <PrivateRoute path="/box-uv-designs/new" component={BoxUvDesignWrapper} title='Add Box Drill Design' />
                <PrivateRoute path="/box-uv-designs/edit" component={BoxUvDesignWrapper} title='Edit Box Drill Design' />
                <PrivateRoute path="/flat-designs/new" component={FlatDesignWrapper} title='Add Flat Design' />
                <PrivateRoute path="/flat-designs/edit" component={FlatDesignWrapper} title='Edit Flat Design' />
                <PrivateRoute path="/box-jobs/:order_number" component={BoxJobWrapper} title='Submit Box Job' />
                <Route exact path="/design">
                    {token ? <Redirect to="/dashboard" /> : <Redirect to="login" />}
                </Route>
                <Route exact path="/upload">
                    {token ? <Redirect to="/dashboard" /> : <Redirect to="login" />}
                </Route>
                <Route path="/design-experiment" component={BoxWrapperSimple} title='Design' />
                <Route path="/design" component={BoxWrapperV3} title='DesignOrder' />
                <Route path="/upload" component={UploadWrapperV3} title='Upload' />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
};

export default Router;