import React, { Component } from "react";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Pagination from "../Layout/Pagination";

import OAuthService from '../Auth/OAuthService';

import {
    Row, Col,
    Container,
    Button,
    // Table
    Alert
} from 'reactstrap';
import BoxOrder from "./BoxOrder";
import BoxJob from "./BoxJob";

import { humanize } from '../../box-helpers'

class Dashboard extends Component {
    state = {
        user: false,
        box_orders: [],
        box_jobs: [],
        box_order_rows: [],
        confirmation_message: "",
        errors: false
    }
    constructor(props){
        super(props);
        this.os = new OAuthService();
    }

    componentDidMount = () => {

        let token = JSON.parse(localStorage.getItem("token"));
        this.os.getUserInfo(token).then((result) => {
            this.setState({
                user: result,
                box_orders: result["box_orders"],
                // box_designs: result["box_designs"],
                // box_uv_designs: result["box_uv_designs"]
            })
        }).catch((error) => {
            console.log(error);
            this.setState({
                box_orders: [],
                // box_designs: [],
                // box_uv_designs: []
            })
        })

        this.os.getBoxJobs(token).then((result) => {
            // console.log("box_jobs", result);
            this.setState({
                box_jobs: result["box_jobs"],
                // box_designs: result["box_designs"],
                // box_uv_designs: result["box_uv_designs"]
            })
        }).catch((error) => {
            console.log(error);
            this.setState({
                box_jobs: [],
                // box_designs: [],
                // box_uv_designs: []
            })
        })

        // this.setOrderRows();

    }

    // resendConfirmation =() => {
    //     let token = JSON.parse(localStorage.getItem("token"));
    //     this.os.resendConfirmation(token).then((result) => {
    //         this.setState({confirmation_message: result['message']})
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    orderRows = () => {
        let orderRows = this.state.box_orders.map( key => {
            return <BoxOrder key={key.id} order={key}></BoxOrder>
        })
        return orderRows;
    }

    boxJobRows = () => {
        let boxJobRows = this.state.box_jobs.map( key => {
            return <BoxJob key={key.id} box_job={key}></BoxJob>
        })
        return boxJobRows;
    }

    sendConfirmation =() => {

        var body_params = new URLSearchParams();
        body_params.append("box_customer[email]", this.state.user.email);

        let token = JSON.parse(localStorage.getItem("client_token"));
        fetch(process.env.REACT_APP_API_URL + '/api/v4/confirmation', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ box_customer: {email: this.state.user.email} })
        })
        .then(response => response.json())
        .then(data => {
            if(data["errors"]){
                // console.log('result error: ',data["errors"]);
                let errors = Object.keys(data["errors"]).map(key => {
                    let human_key = humanize(key);
                    return  human_key + " " + data["errors"][key];
                })

                this.setState({ confirmation_message: errors, errors: errors })
            } else {
                this.setState({ confirmation_message: data.message })
            }

            return data;
        })
        .catch((error) => {

            console.log('errors', error)

            this.setState({confirmation_message: error, errors: true})
            return error;
        });

    }

    render() {
        return (
            <div>
                <Header />
                <Container fluid>
                    <Alert color={ this.state.errors ? "danger" : "success" } isOpen={ this.state.confirmation_message ? true : false} className="text-center mx-4">
                        { this.state.confirmation_message }
                    </Alert>
                    <Alert color="danger" isOpen={ !this.state.confirmation_message && this.state.user && !this.state.user.confirmed_at ? true : false} className="text-center mx-4">
                        Please confirm your email, {  this.state.user.email }. Check your Spam folder if you don't see it.
                        Orders will not show until your account is confirmed.
                        <Button color="primary" className="btn btn-sm mx-3" onClick={this.sendConfirmation} >Resend Confirmation</Button>
                    </Alert>

                    {/* <Row className="m-4">
                        <Col className="col" md={12}>
                            <Alert color="warning" className="text-center">
                                <div className="text-danger"><b>Maintenance Planned.</b> <br />Tayda Box Tool will be down for maintenance for a couple hours on Friday, May 10th and again on Saturday, May 11th. We appreciate your understanding.</div>
                            </Alert>
                        </Col>
                    </Row> */}
                    <Row className="m-4">
                        <Col className="col" md={7}>
                            <Alert color="secondary" className="mb-1">
                                <h5 className="demarginify">How to use this tool?</h5>
                                <div>
                                    <ol className="mb-0">
                                        <li>Order Drill and/or Uv products with your Enclosures on <a href="https://www.taydaelectronics.com/hardware/enclosures.html" target="_blank" rel="noopener noreferrer">TaydaElectronics.com</a>, and each order will appear in Available Orders.</li>
                                        <li>Design your drill templates using My Drill Templates</li>
                                        <li>Upload UV print templates under My UV Print Templates</li>
                                        <li>Configure each order by clicking "Create Job" button in Available Orders.</li>
                                        <li>Enclosure and service order quantities can be configured with different box jobs and designs as needed.</li>
                                    </ol>
                                </div>
                            </Alert>
                            <div className="px-3 pt-2">
                                <Alert color="primary" className="mb-3">
                                    After placing order, please always follow up your order status from here.
                                    If we are able to find any irregularity with any of template before it is done, we will post a message in here. <br />
                                    We won't send an email because many can end up in junk mail. <br/>
                                    <b>Don't forget to use "Create Job" button to assign drill / uv template to your order. It is important. If you don't see your order here (after waiting for a while), please contact us.</b>
                                </Alert>
                                <h4 className="mb-0">Orders</h4>
                                <div className="text-small mb-2"><b>(PLEASE WAIT AT LEAST 15 MINUTES AFTER PLACING ORDER TO SEE YOUR ORDER NUMBER BELOW)</b></div>

                                { this.state.box_orders && (
                                    <div>
                                        <Row className="my-2">
                                            <Col className="col" md={3}>Order #</Col>
                                            <Col className="col" md={4}>Products</Col>
                                            <Col className="col" md={2}>Status</Col>
                                        </Row>

                                        { this.orderRows() }
                                    </div>
                                )}
                                <Alert color="secondary" isOpen={!this.state.box_orders ? true : false}>
                                    {!this.state.user.confirmed_at && (
                                        <h5 className="danger">Your orders will not appear in this tool until you confirm your email.</h5>
                                    )}
                                    <h5><a href="https://www.taydaelectronics.com/hardware/enclosures.html" >Order An Encosure Product</a></h5>
                                </Alert>


                                { this.state.box_jobs && this.state.box_jobs.length > 0 && (
                                    <div>
                                        <hr style={{border: '2px solid rgba(0,0,0,.1)'}}/>
                                        <h4>Box Jobs</h4>
                                        <Row className="my-2">
                                            <Col className="col" md={2}>Order #</Col>
                                            <Col className="col" md={1}>Qty</Col>
                                            <Col className="col" md={4}>Enclosure</Col>
                                            <Col className="col" md={3}>Status</Col>
                                            <Col className="col text-right" md={1}>Created</Col>
                                        </Row>

                                        { this.boxJobRows() }
                                    </div>
                                )}

                            </div>
                        </Col>
                        <Col className="col" md={5}>
                            <div className="mb-2 text-center">
                                <h3>Box Templates</h3>
                            </div>
                            <div>
                                <Alert color="success" className="text-left">
                                    <div className="text-success">
                                        <h5>Latest Features!</h5>
                                        <b>Easy Shapes</b> - You can easily create Rectangles and Triangle shapes with the new Shapes feature in Drill Templates.
                                        <br />
                                        <b>Lines</b> - You can create rectangular drill patterns and any other shapes using Lines with the drill tool.
                                        For an example template for lines. <a href="/box-designs/new?public_key=UzlXTFdzVVBsRnh5WHU4OFdNTCs0UT09Cg==">Click Here</a>
                                        <br />
                                        <b>Enclosure Lids</b> - You can UV print and drill on the lids of enclosure if you can order the service for enclosure lids.
                                    </div>
                                </Alert>
                                <h5 className="float-left">My Drill Templates</h5>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/box-designs/new">+ New Drill Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_designs'}
                                    data_attr={"box_designs"}
                                    redirect_to={"box-designs"}
                                    show_archived={false}
                                />
                            </div>
                            <hr style={{border: '2px solid rgba(0,0,0,.1)'}}/>
                            <div>
                                <h5 className="float-left">My UV Print Templates</h5>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/box-uv-designs/new">+ New UV Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_boxUVDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/box_uv_designs'}
                                    data_attr={"box_uv_designs"}
                                    redirect_to={"box-uv-designs"}
                                    show_archived={false}
                                    show_incomplete={true}
                                />
                            </div>
                            <hr style={{border: '2px solid rgba(0,0,0,.1)'}}/>
                            <div>
                                <h5 className="float-left">My Flat Templates</h5>
                                <a className="btn btn-sm btn-outline-primary float-right" href="/flat-designs/new">+ New Flat Form Template</a>
                                <div style={{ clear: 'both'}}></div>
                                <Pagination
                                    page_size_key={"_flatDesignsPageSize"}
                                    url={process.env.REACT_APP_API_URL + '/api/v4/flat_designs'}
                                    data_attr={"flat_designs"}
                                    redirect_to={"flat-designs"}
                                    show_archived={false}
                                />
                            </div>
                            <hr style={{border: '2px solid rgba(0,0,0,.1)'}}/>
                            <div className="text-right">
                                <a href="/archive">View Archive Box Templates</a>
                            </div>
                            <Alert style={{marginTop: '20px'}} color="dark" isOpen={ true }>
                                <h5>
                                    Order high quality enclosures, custom drill and UV services at <br />
                                    <a href="https://www.taydaelectronics.com/hardware/enclosures.html" target="_blank" rel="noopener noreferrer">Tayda Electronics.com</a>
                                </h5>
                            </Alert>

                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}
export default Dashboard;
